import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { isLoggedIn } from "../services/auth"
import { handleNavAfterLogin } from "../components/login"



export default function Home() {	

	if (isLoggedIn()) {
		handleNavAfterLogin();
		return (
			<Layout>
				<div>Loading...</div>
			</Layout>
			
			// <Layout>
			// 	Click <Link className="text-blue-500" to="/app/chargesheet">here</Link> to submit a charge ticket.
			// </Layout>
		)
	}
	else {
		return (
			<Layout>
				Click <Link className="text-blue-500" to="/app/login">here</Link> to login.
			</Layout>
		)
	}
}

// export const Head = () => (
// 	<>
// 		<title>HPM Charge</title>
// 		<meta name="description" content="HPM Charge web app" />
// 		<script type='module' src='https://us-west-2b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'></script>
// 	</>
// )
